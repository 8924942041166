import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  private connectionStatus$ = new BehaviorSubject<boolean>(navigator.onLine);

  constructor(private zone: NgZone) {
    window.addEventListener('online', () => this.updateConnectionStatus(true));
    window.addEventListener('offline', () => this.updateConnectionStatus(false));
  }

  private updateConnectionStatus(status: boolean): void {
    this.zone.run(() => this.connectionStatus$.next(status));
  }

  public getConnectionStatus(): Observable<boolean> {
    return this.connectionStatus$.asObservable();
  }
}
