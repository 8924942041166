import { Component, Input } from '@angular/core';

/**
 * Online Offline message
 */
@Component({
  selector: 'bot-online',
  templateUrl: './online.component.html',
  styleUrls: ['./online.component.scss'],
})
export class OnlineComponent {
}
