import { Component, OnInit } from '@angular/core';
import { TemporarySsoTicketAuthenticatorService } from './services/sso/temporary-sso-ticket-authenticator.service';
import { environment } from '../environments/environment';
import { ConnectionService } from './modules/sharedComponents/services/connection.service';

/**
 * App Component -
 */
@Component({
  selector: 'afe-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  public isOnline = true;

  constructor(private ssoTicket: TemporarySsoTicketAuthenticatorService, private connectionService: ConnectionService) {
    this.connectionService.getConnectionStatus().subscribe((status) => {
      if (status && !this.isOnline) {
        window.location.reload();
      }

      this.isOnline = status;
    });
  }

  public ngOnInit(){
    // this.ssoticket.initiate();
    if (environment.controlVariable) {
      console.log('A variável de controle está ativada.');
    } else {
      console.log('A variável de controle está desativada.');
    }
  }
}
